<template>
  <v-container class="mt-3 mb-16">
    <div class="banner-with-text">
      <label for="">วิธีการสั่งซื้อสินค้า</label>
      <img
        src="@/assets/images/banner/how-2-buy-banner.png"
        class="hidden-sm-and-down"
      >
    </div>
    <div class="info-paragraph">
      <label
        class="info-text"
      >1. กดเลือกสินค้าที่ต้องการในหน้ารายการสินค้า เพื่อดูรายละเอียดสินค้า หรือกด “เพิ่มลงตะกร้าสินค้า” เพื่อความรวดเร็วในการสั่งซื้อ </label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_1.png"
      >
      <label
        class="info-text"
      >2. ท่านสามารถเพิ่ม/ลด จำนวนสินค้าได้ </label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_2.png"
      >
      <label
        class="info-text"
      >3. ตัวเลข Capsule คือแต้มสำหรับสะสมที่ท่านจะได้รับ เพื่อแลกของสมนาคุณ</label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_3.png"
      >
      <label
        class="info-text"
      >4. เมื่อเลือกสินค้าเสร็จเรียบร้อยให้กดปุ่ม “เพิ่มลงในตะกร้าสินค้า”</label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_4.png"
      >
      <label
        class="info-text"
      >5. ท่าสามารถกดที่ตะกร้าสินค้าเพื่อดูรายการสินค้าทั้งหมดที่ถูกหยิบลงตะกร้า และสามารถเพิ่ม/ลด หรือลบ สินค้าได้ โดยในตะกร้าสินค้าจะบอกยอดรวมของราคาสินค้าพร้อมทั้ง Capsule สะสมที่ท่านจะได้รับ เมื่อตรวจสอบสอบสินค้าเรียบร้อย สามารถกด “สั่งซื้อสินค้า” เพื่อเปิดบิลสินค้า</label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_5.png"
      >
      <label
        class="info-text"
      >6. เมื่อกด “สั่งซื้อสินค้า” สำเร็จ จะพบกับสรุปรายการสั่งซื้อ</label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_6.png"
      >
      <label
        class="info-text"
      >7. ท่านสามารถเพิ่มที่อยู่จัดส่งได้ที่ปุ่ม “เพิ่ม” เมื่อกรอกที่อยู่จัดส่งเรียบร้อย สามารถเลือกสถานที่นัดรับ/จัดส่งสินค้า ได้ตามช่องทางที่ท่านสะดวก</label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_7.png"
      >
      <label
        class="info-text"
      >8. เลือกรูปแบบการชำระเงินที่สะดวก และกรอกโค้ดส่วนลด (ถ้ามี)</label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_8.png"
      >
      <label
        class="info-text"
      >9. ตรวจสอบยอดเงินที่ต้องชำระให้ครบถ้วน จากนั้นกด “ยืนยันการสั่งซื้อ” โดยจะต้องโอนเงินภายใน 30 วันหลังจากยืนยันการสั่งซื้อ</label>
      <img
        class="info-img"
        src="@/assets/images/how2buy/instruction_9.png"
      >
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'DeliveryInfo',
};
</script>
<style scoped>
.banner-with-text {
  height: 120px;
  background-color: #f6efff;
  width: 100%;
  display: flex;
}

.banner-with-text > * {
  display: inline-flex;
}

.banner-with-text > label {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.banner-with-text > img {
  margin-left: auto;
}

.info-paragraph {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}

.info-paragraph > .info-text {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0px !important;
}

.info-paragraph > .info-img {
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 12px;
}
</style>